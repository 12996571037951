import './BlogArea.css';
import BlogPosts from "../../Constant/BlogPosts"
const BlogArea = () => {

  return (

    <div className="BlogAreaContainer">
      {BlogPosts.map((item, index) => (
      <div key={index} className="myCard">
        <h2>{item.title}</h2>
        <h5>{item.link}</h5>
        <div className="fakeimg"/>
        <span className="Blog">
        {`${item.content}`}
         </span>
      </div>
      ))}


    </div>

  );
}

export default BlogArea;