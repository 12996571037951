import './App.css';
import BlogArea from './components/BlogArea/BlogArea';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import LeftPane from './components/LeftPane/LeftPane';
import RightPane from './components/RightPane/RightPane';

const App = () => {
  return (
    <div className="App">
      <div className="Header"> <Header /></div>
      <div className='ContentArea'>
        <LeftPane />
        <BlogArea />
        <RightPane />
      </div>
      <Footer />
    </div>

  );
}

export default App;
