import './Header.css';
const Header = () => {
  return (
    <div className="HeaderContainer">
<nav className="  navbar bg-light">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">Gizmo Geek</a>
  </div>
</nav>

</div>
  );
}

export default Header;
